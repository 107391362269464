@font-face {
    font-family: 'Segoe UI';
    src: url('../content/fonts/segoe-ui.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('../content/fonts/segoe-ui-semibold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Segoe UI';
    src: url('../content/fonts/segoe-ui-bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}
